<template>
    <div class="container">
        <el-row class="listHeader">
            <el-col class="icon">
                <div>
                    <i class="el-icon-trophy" style="font-size:32px"></i>
                </div>
                <div>赛事</div>
            </el-col>
            <el-col>
                <div>
                    <el-button type="primary" size="medium" v-href="`/Match/CompetitionDetail`"><i class="el-icon-plus"></i> 创建赛事</el-button>
                    <el-button type="primary" size="medium" v-href="'/Match/CompetitionDetail/0/3'" v-if="isOldLeague"><i class="el-icon-plus"></i> 补充历史赛事</el-button>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-form>
                <el-form-item label="赛事状态筛选：">
                    <el-button v-for="(item,index) in statusList" :key="index"
                        size="small"
                        :plain="item.id == status ? false : true"
                        :type="item.id == 7 ? 'danger' : 'primary'"
                        @click="status = item.id"
                    >{{item.text}}</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row style="height:calc(100% - 180px)">
            <el-table
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="list"
                border
                style="width: 100%"
                height="100%"
                size="mini"
            >
                <el-table-column
                    prop="SeasonName"
                    label="赛季"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="LeagueName"
                    label="赛事名称">
                </el-table-column>
                <el-table-column
                    label="比赛时间">
                    <template slot-scope="scope">
                        <span v-if="scope.row.GameTime">{{scope.row.GameTime.BeginTime | dateFormat('yyyy-MM-dd')}} ~ {{scope.row.GameTime.EndTime | dateFormat('yyyy-MM-dd')}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="CurrentCertificateLevel"
                    label="赛事等级"
                    width="50">
                </el-table-column>
                <el-table-column
                    label="特殊状态"
                    width="80">
                    <template slot-scope="scope">
                        {{['','赛事取消','赛事延期','赛事中断'][scope.row.AbnormalStatus || 0]}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="赛事状态"
                    width="100">
                    <template slot-scope="scope">
                        <span :style="[7,8,9].includes(scope.row.LeagueNowStatus) ? 'color:red' : ''">{{['未发布','未开赛','比赛中','已结束','审核中','审核不通过','已取消','延期开赛','已中断'][scope.row.LeagueNowStatus - 1]}}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="130" label="赛事操作">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="info"
                            v-if="scope.row.IsLastSeason ? (scope.row.ReviewStatus != 2 && [1,2,3,4,6,7,8,9].includes(scope.row.LeagueNowStatus)) : [1,6].includes(scope.row.LeagueNowStatus)"
                            v-href="`/Match/CompetitionDetail/${scope.row.SubLeagueId}/1`"
                        >编辑</el-button>
                        <el-button 
                            size="mini"
                            type="success"
                            v-if="[1].includes(scope.row.LeagueNowStatus)"
                            @click="publish(scope.row.SubLeagueId)"
                        >发布</el-button>
                        <el-button 
                            size="mini"
                            type="primary"
                            v-if="scope.row.IsLastSeason ? ([5].includes(scope.row.LeagueNowStatus) || scope.row.ReviewStatus == 2) : [2,3,4,5].includes(scope.row.LeagueNowStatus)"
                            v-href="`/Match/LookCompetitionDetail/${scope.row.SubLeagueId}`"
                        >查看</el-button>
                        <el-button 
                            size="mini"
                            type="primary"
                            v-if="scope.row.ReviewStatus != 2"
                            v-href="`/Match/ImportEnroll/${scope.row.SubLeagueId}?name=${scope.row.LeagueName}`"
                        >上传报名册</el-button>
                        <el-button 
                            size="mini"
                            type="primary"
                            v-href="`/Match/CompetitionResults/${scope.row.ReviewStatus != 2 ? `${scope.row.SubLeagueId}/1` : `${scope.row.SubLeagueId}`}?name=${scope.row.LeagueName}`"
                        >比赛成绩</el-button>
                        <el-button 
                            size="mini"
                            type="warning"
                            v-if="[6].includes(scope.row.LeagueNowStatus)"
                            @click="showAuditReason(scope.row.AuditReason)"
                        >显示原因</el-button>
                        <el-button 
                            size="mini"
                            :type="getStatus(scope.row.MatchGroupSetting).type"
                            @click="openQRCode(scope.row)"
                        >参赛群二维码 ({{getStatus(scope.row.MatchGroupSetting).dayText}})</el-button>
                        <el-button 
                            size="mini"
                            type="success"
                            @click="newsChannel(scope.row.SubLeagueId)"
                        >关联资讯栏目<template v-if="scope.row.ChannelCount > 0">（{{scope.row.ChannelCount}})</template>
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    label="复审状态"
                    width="100">
                    <template slot-scope="scope">
                        {{['未提交','审核中','审核通过','审核不通过'][scope.row.ReviewStatus]}}
                    </template>
                </el-table-column>
                <el-table-column label="复审操作">
                    <template slot-scope="scope">
                        <el-button 
                            size="mini"
                            type="info"
                            v-if="[0,3].includes(scope.row.ReviewStatus)"
                            v-href="`/Match/SeasonRecheckDetail/${scope.row.SeasonId}/1`"
                        >编辑</el-button>
                        <el-button 
                            size="mini"
                            type="success"
                            v-if="[0].includes(scope.row.ReviewStatus)"
                            @click="recheckSend(scope.row.SeasonId)"
                        >提交</el-button>
                        <el-button 
                            size="mini"
                            type="primary"
                            v-if="[1,2].includes(scope.row.ReviewStatus)"
                            v-href="`/Match/LookSeasonRecheckDetail/${scope.row.SeasonId}`"
                        >查看</el-button>
                        <el-button 
                            size="mini"
                            type="warning"
                            v-if="[3].includes(scope.row.ReviewStatus)"
                            @click="showAuditReason(scope.row.ReviewAuditReason)"
                        >显示原因</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-dialog title="关联资讯栏目设置" :visible.sync="channelVisible" :close-on-click-modal="false" :destroy-on-close="true" width="400px" >
            <el-form ref="channelForm" :model="channelForm" size="small" >
                <el-row v-for="item,index in channelForm.data" :key="index">
                    <el-col :span="8">
                        <el-form-item :prop="`data.${index}.checked`" style="margin:0 !important">
                            <el-checkbox v-model="item.checked">{{item.label}}</el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="修改名称" :prop="`data.${index}.name`" :rules="channelRules.name" style="margin:0 !important">
                            <el-input v-model="item.name" style="width: 100px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="channelVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitChannel">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="赛事参赛群二维码" :visible.sync="QRCodeVisible" :close-on-click-modal="false" :destroy-on-close="true" width="800px" >
            <div class="QRCode_content">
                <div class="QRCode_image">
                    <div class="image_box">
                        <img :src="groupQRCode.url" v-if="groupQRCode.url">
                        <div class="hint" v-else>
                            <p>
                                请打开微信APP，进入您的群聊，<br>
                                点击右上角菜单，选择[群二维码]，<br>
                                看到二维码之后，点击[保存图片]，<br>
                                然后在此处上传。
                            </p>
                            <p>
                                <span style="color: red">请勿上传黑色 (深色)背景的群二维码图片!</span><br>
                                微信APP底部点击[我]，<br>
                                依次点击[设置] - [通用] - [深色模式]，<br>
                                关闭[跟随系统]，并选择[普通模式]，<br>
                                然后保存退出。
                            </p>
                        </div>
                    </div>
                    <el-button size="small" type="warning" class="upload_QRCode" @click="uploadImage">
                        <span>上传群二维码</span>
                    </el-button>
                </div>
                <div class="pageQRCode_info">
                    <h3>群二维码展示页面</h3>
                    <p>
                        <label>页面URL：</label>
                        <el-link type="primary" :underline="false">{{pageUrl}}</el-link>
                    </p>
                    <p>
                        <label>页面二维码：</label>
                        <span>（您也可使用URL自行生成）</span>
                    </p>
                    <div class="pageQRCode_image">
                        <div class="image_box">
                            <img :src="pageQRCodeUrl">
                        </div>
                        <div class="image_operate">
                            <el-button size="mini" @click="copyURL()">复制URL</el-button>
                            <br>
                            <el-button size="mini" @click="saveQRCode()">保存二维码</el-button>
                        </div>
                    </div>
                    <p><span>每个赛事的URL和二维码将保持固定，在上传新的群二维码之后不会发生变化。</span></p>
                    <p>
                        <label>页面入口：</label>
                        <el-checkbox label="赛事详情页(必选)" :value="true" disabled ></el-checkbox>
                        <el-checkbox label="报名提交成功对话框" v-model="groupQRCode.enrollRemind"></el-checkbox>
                    </p>
                    <el-descriptions title="失效通知：" :column="1" :labelStyle="{'width': '150px', 'align-items': 'center', 'justify-content': 'end'}">
                        <el-descriptions-item label="群二维码有效期">
                            <el-date-picker
                                size="mini"
                                v-model="groupQRCode.deadline"
                                type="date"
                                placeholder="选择失效日期"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-descriptions-item>
                        <el-descriptions-item label="接收过期通知手机号">
                            <el-input size="mini" type="text" v-model="groupQRCode.phone" placeholder="输入手机号，多个逗号分隔"></el-input>
                        </el-descriptions-item>
                    </el-descriptions>
                    <p style="margin-top: 0"><span>系统将在有效期截止前1天和当天短信通知您上传新的群二维码。</span></p>
                    <div class="operation">
                        <el-button type="primary" @click="save()">保 存</el-button>
                        <el-button @click="QRCodeVisible = false">取 消</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    GetMySubleagueList,
    SubmitSeasonReviewApply,
    CheckHaveSubleague,
    SaveSubleagueMatchGroupSetting,
    GetUploadOptionsForEditSubLeague
} from '@/api'
import {
    GetSubleagueSscmsChannelConfig,
    SaveSubleagueSscmsChannelConfig
} from '@/api/news';
import {post} from '@/api/oldApi';
import {
    dateFormat,
    copy
} from '@/assets/js/index';
import QRCode from 'qrcode';
export default {
    data(){
        return {
            isOldLeague:false,
            status:null,
            statusList:[{
                id:null,
                text:'全部'
            },{
                id:1,
                text:'未发布'
            },{
                id:5,
                text:'审核中'
            },{
                id:6,
                text:'审核不通过'
            },{
                id:2,
                text:'未开赛'
            },{
                id:3,
                text:'比赛中'
            },{
                id:4,
                text:'已结束'
            },{
                id:7,
                text:'特殊状态'
            }],
            list:[],
            loading:false,
            channelVisible: false,
            channelForm: {
                id: 0,
                data: []
            },
            QRCodeVisible: false,
            groupQRCode: {
                id: 0,
                name: '',
                url: '',
                enrollRemind: false,
                deadline: '',
                phone: ''
            },
            pageUrl: '',
            pageQRCodeUrl: '',
            ossConfig: {}
        }
    },
    watch:{
        status(){
            this.getList();
        }
    },
    created(){
        CheckHaveSubleague().then(data=>{
            if(data.Code == 0){
                this.isOldLeague = data.IsHave;
            }
        })
        this.getList();
    },
    computed: {
        channelRules(){
            return {
                name:[{
                    validator: (rule, value, callback)=>{
                        if(value){
                            if(value.length < 2 || value.length > 4){
                                callback(new Error('请输入2~4个字符！'));
                            }else{
                                callback();
                            }
                        }else{
                            callback();
                        }
                    },
                    trigger: 'blur'
                }]
            }
        }
    },
    methods:{
        getList(){
            this.loading = true;
            GetMySubleagueList({
                LeagueNowStatus: this.status == 7 ? null : this.status,
                IsAbnormalStatus: this.status == 7
            }).then((data)=>{
                this.loading = false;
                if(data.Code == 0){
                    this.list = data.MySubLeagueList;
                }
            }).catch(() => {
                this.loading = false;
            })
        },
        //赛事发布
        publish(Id){
            this.$confirm('发布之后不可修改赛事详情，确认发布？').then(() => {
                let loading = this.$loading({
                    lock: true,
                    text: '正在发布',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                post('Match/Entrance',{
                    action: 'Upt_league_status',
                    user_token:'',
                    enroll_type: 0,
                    type: 1,
                    league_id: Id
                }).then((data) => {
                    loading.close();
                    if(data.Code == 0){
                        this.$message.success('发布成功');
                        this.getList();
                    }
                }).catch(() => {
                    loading.close();
                })
            })
        },
        //复审提交
        recheckSend(seasonId){
            SubmitSeasonReviewApply({
                SeasonId: seasonId
            }).then(data=>{
                if(data.Code == 0){
                    this.$message.success('提交成功');
                    this.getList();
                }
            })
        },
        showAuditReason(text){
            this.$alert(text, {
                title:"审核不通过原因",
                confirmButtonText: '确定',
                closeOnClickModal:true
            });
        },
        //关联资讯栏目
        initChannel(){
            this.$set(this.channelForm, 'data', [{
                label:'公告资讯',
                type: "notice",
                id: 0,
                checked: false,
                isContent: false,
                name: ""
            },{
                label:'赛程赛果',
                type: "progress",
                id: 0,
                checked: false,
                isContent: false,
                name: ""
            },{
                label:'精彩瞬间',
                type: "album",
                id: 0,
                checked: false,
                isContent: false,
                name: ""
            }]);
        },
        newsChannel(subLeagueId){
            this.channelForm.id = subLeagueId;
            this.channelVisible = true;
            this.initChannel();
            GetSubleagueSscmsChannelConfig({
                SubLeagueId: subLeagueId
            }).then(data=>{
                if(data.Code == 0){
                    let channels = this.channelForm.data,
                        arr = data.ChannelConfigs || [];
                    for(let item of channels){
                        let obj = arr.filter(n => n.ChannelType == item.type)[0];
                        if(obj){
                            item.id = obj.ChannelId;
                            item.name = item.label != obj.ChannelName ? obj.ChannelName : '';
                            item.checked = true;
                            item.isContent = obj.CountOfContents == 1;
                        }
                    }
                }
            })
        },
        submitChannel(){
            this.$refs['channelForm'].validate((valid)=>{
                if(valid){
                    let channels = this.channelForm.data,
                        arr = [],
                        deletes = [];
                    for(let item of channels){
                        if(!item.checked && item.isContent){
                            deletes.push(item.label);
                        }
                        if(item.checked){
                            arr.push({
                                "type": item.type,
                                "name": item.name || item.label,
                                "id": item.id
                            })
                        }
                    }
                    if(deletes.length > 0){
                        this.$confirm(`栏目${deletes.join('、')}下已发布内容，是否确认删除？`).then(() => {
                            this.saveChannel(this.channelForm.id, arr);
                        })
                    }else{
                        this.saveChannel(this.channelForm.id, arr);
                    }
                }
            })
        },
        saveChannel(subLeagueId, channels){
            SaveSubleagueSscmsChannelConfig({
                "SubLeagueId": subLeagueId,
                "ChannelConfigs": channels
            }).then(data=>{
                if(data.Code == 0){
                    this.$message.success('修改成功');
                    this.getList();
                    this.channelVisible = false;
                }
            })
        },
        getStatus(obj){
            let {
                MatchGroupQrcodeUrl,
                MatchGroupQrcodeDeadline
            } = obj || {};
            if(!MatchGroupQrcodeUrl) return {
                type: 'info',
                dayText: '未设置'
            }
            let deadline = new Date(dateFormat(MatchGroupQrcodeDeadline, 'yyyy-MM-dd')).getTime(),
                date = new Date(dateFormat(new Date(), 'yyyy-MM-dd')).getTime();
            let day = parseInt((deadline - date) / 1000 / 60 / 60 / 24),
                type = '',
                dayText = '';
            if(day < 0){
                type = 'danger';
                dayText = '已失效';
            }else if(day == 0){
                type = 'danger';
                dayText = '剩0天';
            }else if(day == 1){
                type = 'danger';
                dayText = '剩1天';
            }else if(day == 2){
                type = 'warning';
                dayText = '剩2天';
            }else{
                type = 'success';
                dayText = `剩${day}天`;
            }
            return {
                type,
                dayText
            }
        },
        openQRCode(obj){
            let loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            });
            GetUploadOptionsForEditSubLeague({
                SubLeagueId: obj.SubLeagueId
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    let {
                        MatchGroupQrcodeUrl,
                        MatchGroupQrcodeDeadline,
                        MatchGroupContactPhones,
                        MatchGroupEnrollRemind
                    } = obj.MatchGroupSetting || {};
                    this.groupQRCode = {
                        id: obj.SubLeagueId,
                        name: obj.LeagueName,
                        url: MatchGroupQrcodeUrl || '',
                        enrollRemind: !!(MatchGroupEnrollRemind || false),
                        deadline: MatchGroupQrcodeDeadline || '',
                        phone: MatchGroupContactPhones ? MatchGroupContactPhones.join(',') : ''
                    }
                    this.pageUrl = `https://api.dazhongyundong.com/web/#/Match/GroupQRCode?leagueId=${obj.SubLeagueId}`;
                    QRCode.toDataURL(this.pageUrl, { width: 200, margin: 0}).then(res=>{
                        this.pageQRCodeUrl = res;
                    })
                    this.ossConfig = data.UploaderOptions;
                    this.QRCodeVisible = true;
                }
            }).catch(()=>{
                loading.close();
            })
        },
        save(){
            let groupQRCode = this.groupQRCode;
            if(!groupQRCode.url){
                this.$message.error('请上传赛事群二维码');
            }else if(!groupQRCode.deadline){
                this.$message.error('请选择失效日期');
            }else if(!groupQRCode.url){
                this.$message.error('');
            }else{
                let phones = groupQRCode.phone ? groupQRCode.phone.replace(' ', '').replace(/,，/, ',').split(',') : [];
                SaveSubleagueMatchGroupSetting({
                    "SubLeagueId": groupQRCode.id,
                    "MatchGroupSetting": {
                        "MatchGroupQrcodeUrl": groupQRCode.url,
                        "MatchGroupQrcodeDeadline": groupQRCode.deadline,
                        "MatchGroupContactPhones": phones,
                        "MatchGroupEnrollRemind": +groupQRCode.enrollRemind
                    }
                }).then(data=>{
                    if(data.Code == 0){
                        this.$message.success('保存成功');
                        this.getList();
                        this.QRCodeVisible = false;
                    }
                })
            }
        },
        copyURL(){
            copy(this.pageUrl).then(()=>{
                this.$message.success('复制成功');
            })
        },
        saveQRCode(){
            this.openDownloadXLSXDialog(this.base64ToBlob(this.pageQRCodeUrl), this.groupQRCode.name + '[参赛群二维码展示页面]');
        },
        uploadImage(){
            this.$file.cloose({
                accept: 'image/*'
            }).then(file=>{
                if(!file.type.includes('image')){
                    this.$message.error('不支持此文件类型');
                    return;
                }
                let loading = this.$loading({
                    lock: true,
                    text: '图片上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.8)'
                });
                this.$file.ossUpload({
                    file,
                    uploadOptions: this.ossConfig
                }).then(data=> {
                    loading.close();
                    this.groupQRCode.url = data.url;
                    let date = new Date(dateFormat(new Date(), 'yyyy-MM-dd')).getTime();
                    let day = (1000 * 60 * 60 * 24) * 7;
                    this.groupQRCode.deadline = dateFormat(date + day, 'yyyy-MM-dd');
                }).catch(()=>{
                    loading.close();
                    this.$message.error('图片上传失败，请重试！');
                });
            })
        },
        openDownloadXLSXDialog(url, saveName){
            if(typeof url == 'object' && url instanceof Blob){
                url = URL.createObjectURL(url); // 创建blob地址
            }
            var aLink = document.createElement('a');
            aLink.href = url;
            aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
            var event;
            if(window.MouseEvent) event = new MouseEvent('click');
            else{
                event = document.createEvent('MouseEvents');
                event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            }
            aLink.dispatchEvent(event);
        },
        //base64转blob
        base64ToBlob(code) {
            let parts = code.split(';base64,');
            let contentType = parts[0].split(':')[1];
            let raw = window.atob(parts[1]);
            let rawLength = raw.length;

            let uInt8Array = new Uint8Array(rawLength);

            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], {type: contentType});
        },
    }
}
</script>
<style scoped>
    ::v-deep .el-dialog__body{
        padding-top: 20px;
    }
    .QRCode_content{
        width: 100%;
        height: 100%;
        display: flex;
    }
    .QRCode_content .QRCode_image{
        width: 300px;
        height: auto;
        border: 1px solid #ccc;
    }
    .QRCode_image .image_box{
        width: 100%;
        height: calc(100% - 35px);
    }
    .QRCode_image .image_box img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .QRCode_image .hint{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 1.6em;
    }
    .QRCode_image .hint p{
        text-align: center;
        margin: 15px 0;
    }
    .QRCode_image .upload_QRCode{
        position: relative;
        display: block;
        margin: 0 auto;
    }
    .QRCode_image .upload_QRCode input{
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
    }
    .QRCode_content .pageQRCode_info{
        width: calc(100% - 300px);
        padding-left: 30px;
        color: #333333;
        box-sizing: border-box;
    }
    .pageQRCode_info p{
        margin: 8px 0;
    }
    .pageQRCode_info p>label{
        font-weight: bold;
    }
    .pageQRCode_info p span{
        font-size: 0.9em;
        color: #a4a4a4;
    }
    .pageQRCode_image{
        display: flex;
    }
    .pageQRCode_image .image_box{
        width: 100px;
        height: 100px;
        margin: 0 10px;
    }
    .pageQRCode_image .image_box img{
        width: 100%;
        height: 100%;
    }
    .pageQRCode_image .image_operate .el-button{
        margin-bottom: 10px;
        width: 100px;
    }
    .pageQRCode_info .el-descriptions{
        margin-top: 20px;
    }
    .pageQRCode_info .operation{
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
    .pageQRCode_info .operation .el-button{
        width: 100px;
        margin: 0 10px;
    }
</style>